import React, { useState, useRef, useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useMount, useUnmount } from 'react-use'
import { motion, AnimatePresence } from 'framer-motion'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { use100vh } from 'react-div-100vh'

import { media } from '../../styles/utils'
import { container, padding, type } from '../../styles/global'
import { navigation } from '../../styles/type'

import { getGlobalData, renderLink } from '../../utils'
import { MenuContext } from '../Menu/context'
import { IsMobileContext } from '../../context/IsMobileContext'

import { maxWidth } from '../../styles/global' 

const Menu = props => {
    const [visible, setVisible] = useState(false)
    const MenuEl = useRef(null)
    const menu = useContext(MenuContext)
    const { isMobile } = useContext(IsMobileContext)
    const { global: data } = getGlobalData()
    const screenHeight = use100vh()
    // console.log(visible)

    useEffect(() => {
        setVisible(menu.active)
    }, [menu.active])

    useEffect(() => {
        if (isMobile && visible) {
                disableBodyScroll(MenuEl.current)
        } else {
            clearAllBodyScrollLocks()
        }
    }, [visible])

    const renderNav = () => {
        if (!data.site_navigation) return
        return data.site_navigation.map((item, i) => {
            const link = item?.link
            if (!link) return
            return renderLink(link, i)
        })
    }

    return (
        <Wrapper
            ref={MenuEl}
            onClick={() => menu.toggleActive()}
            visible={visible}
        >
            <Container>
                <AnimatePresence>
                    {visible && (
                        <MenuWrapper
                            key={'menu-wrapper'}
                            initial={{
                                x: '100%',
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1
                            }}
                            exit={{
                                x: '100%',
                                opacity: 0
                            }}
                            transition={{
                                duration: 0.4
                            }}
                            style={{
                                height: `${screenHeight}px`
                            }}
                        >
                            <Nav>
                                {renderNav()}
                            </Nav>
                        </MenuWrapper>
                    )}
                </AnimatePresence>
            </Container>
        </Wrapper>
    )
}

// Shared

const Wrapper = styled.div`
    ${tw`fixed inset-0 w-full`};
    z-index: 10;
    user-select: none;

    ${props => {
        if (!props.visible)
            return css`
                pointer-events: none;
            `
    }}
`

const Container = styled.div`
    ${container}
    ${tw`relative h-full`};
`

const MenuWrapper = styled(motion.div)`
    ${tw`absolute inset-y-0 right-0 flex flex-col justify-end h-full bg-white`};
    padding: 5rem;
    width: 512px;
    max-width: 100%;

    &::after {
        content: '';
        ${tw`absolute inset-y-0 flex bg-white`};
        width: calc(100vw - ${maxWidth/2}px);
    }
`

const Nav = styled.div`
    ${tw`relative z-10 flex flex-col items-start border-t border-black`};
    padding-top: 5rem;

    a {
        ${tw`font-display text-black`};
        font-size: 5rem;
        font-weight: 300;
        line-height: 1;
        /* cursor: pointer; */
        transition: color 0.3s ease;

        &:hover {
            ${tw`text-blue`};
        }

        ${media.mob`
            &.active {
                ${tw`text-blue`};
            }
        `}
    }
`

export default Menu

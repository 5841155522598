import React, { useState, useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { useMouse } from 'react-use'

import { media } from '../../styles/utils'
import { type } from '../../styles/global'

const Cursor = props => {
    const { docX, docY } = props

    return (
        <Wrapper
            id="cursor"
            style={{ left: docX ? `${docX}px` : '-100%', top: docY ? `${docY}px` : '-100%' }}
        >
            <Image
                id="cursor-image"
                src={require('../../assets/images/icons/cursor.svg')}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`fixed z-30 top-0 left-0`};
    pointer-events: none;
    transform: translate(-50%, -50%);
    top: -100%;
    left: -100%;
    width: 21px;
    height: 21px;

    ${media.tab`
        display: none!important;
    `}
`

const Image = styled.img`
    ${tw`absolute inset-0`};
`

export default Cursor

import React, { useContext, useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import Transition from '../../transition'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'
import { use100vh } from 'react-div-100vh'

import { MetaTags, Menu, Footer, Slider } from '../'
import { media, useBreakpoint } from '../../styles/utils'
import { container } from '../../styles/global'

import { IsMobileContext } from '../../context/IsMobileContext'
import { IsHomeContext } from '../../context/IsHomeContext'

let AnimatedCursor = null

const Layout = ({ children, meta, hideFooter, footerTheme, slides }) => {
    const isMobile = useContext(IsMobileContext)
    const { isHome } = useContext(IsHomeContext)
    const detectMob = useBreakpoint('mob')
    const screenHeight = use100vh()
    const [ mounted, setMounted ] = useState(false)

    useMount(() => {
        isMobile.setIsMobile(detectMob)
        AnimatedCursor = require('react-animated-cursor')
        setMounted(true)
    })

    useEffect(() => {
        isMobile.setIsMobile(detectMob)
    }, [detectMob])
    
    let topMargin

    if (isHome) {
        if (isMobile.isMobile) {
            topMargin = '470px'
        } else {
            topMargin = `${screenHeight}px`
        }
    }

    return (
        <>
            <MetaTags {...meta} />
            <Transition>
                <Menu />
                {slides && (
                    <Slider
                        slides={slides}
                    />
                )}
                {mounted && !isMobile.isMobile && (
                    <AnimatedCursor
                        color='195, 102, 70'
                        innerSize={21}
                        innerScale={1.3}
                        outerSize={0}
                        outerScale={0}
                        outerAlpha={0}
                    />
                )}
                <Wrapper
                    style={{
                        minHeight: isHome && !isMobile.isMobile && `${screenHeight}px`,
                        marginTop: topMargin && topMargin
                    }}
                >
                    {children}
                    <Footer theme={footerTheme} hide={hideFooter} bgColour={isHome && 'white'} />
                </Wrapper>
            </Transition>
        </>
    )
}

const Wrapper = styled.div`
    ${tw`relative flex flex-col`};

    ${media.mob`
        ${tw`block`};
    `}
`

export default Layout

import React from 'react'
import styled, { css } from 'styled-components'
import { GlobalStyles } from './src/styles/global'
import { MenuProvider } from './src/components/Menu/context'
import { IsMobileProvider } from './src/context/IsMobileContext'
import { IsHomeProvider } from './src/context/IsHomeContext'
import { ThemeProvider } from './src/context/ThemeContext'

import { Header } from './src/components'
import BGTransition from './src/bgTransition'

export default ({ element, props }) => {
    return (
        <IsHomeProvider>
            <IsMobileProvider>
                <MenuProvider>
                    <ThemeProvider>
                        <GlobalStyles />
                        <BGTransition>
                            <Header />
                            {element}
                        </BGTransition>
                    </ThemeProvider>
                </MenuProvider>
            </IsMobileProvider>
        </IsHomeProvider>
    )
}
import React, { useState } from 'react'
import { colours } from '../styles/global'

export const ThemeContext = React.createContext()

const ThemeProvider = ({ children }) => {
    const [Theme, setTheme] = useState({
        headerColour: colours.white,
        bgColour: colours.white,
        footerColour: colours.blue
    })

    return (
        <ThemeContext.Provider value={{ Theme: Theme, setTheme: setTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}


export default ThemeContext

export { ThemeProvider }
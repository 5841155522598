const colours = {
    black: '#000000',
    blacktwo: '#393939',
    grey: '#a3a3a3',
    white: '#ffffff',

    peach: '#e3dfd1',
    blue: '#1152a1',
    lightblue: '#e7edf5',
}

export default colours

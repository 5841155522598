import React from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { motion, AnimatePresence, useTransform } from 'framer-motion'

import { getGlobalData } from '../../utils'
import { media } from '../../styles/utils'
import { container, padding, bgImage, bgIcon, type } from '../../styles/global'
import colours from '../../styles/colours'

const Footer = (props) => {
    const { theme, hide, bgColour } = props
    const { global: data } = getGlobalData()

    if (hide) return false

    const renderEmail = () => {
        if (!data.contact_email) return

        return (
            <Email>
                <span>email us&nbsp;</span>
                <ExternalLink
                    href={`mailto:${data.contact_email}`}
                >
                    {data.contact_email}
                </ExternalLink>
            </Email>
        )
    }

    const renderPhone = () => {
        if (!data.contact_phone) return

        return (
            <Phone>
                <span>call us&nbsp;</span>
                <ExternalLink
                    href={`tel:${data.contact_phone}`}
                >
                    {data.contact_phone}
                </ExternalLink>
            </Phone>
        )
    }

    const renderFacebookSVG = fillColour => {
        return (
            <svg width="13px" height="23px" viewBox="0 0 13 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="facebook" stroke="none" strokeWidth="1" fillRule="nonzero">
                    <path fill={`${fillColour ? fillColour : colours.black}`} d="M12.259,4.01733333 L12.259,0.00479166667 L8.708375,0.00479166667 C5.89211398,0.00479166667 3.60908333,2.28782231 3.60908333,5.10408333 L3.60908333,8.77929167 L0,8.77929167 L0,12.9729583 L3.60908333,12.9729583 L3.60908333,22.7747917 L8.01741667,22.7747917 L8.01741667,12.9039583 L11.316,12.9039583 L11.945625,8.74 L8.01645833,8.74 L8.01645833,5.77683333 C8.01645833,4.80508832 8.80421332,4.01733333 9.77595833,4.01733333 L12.259,4.01733333 Z" id="Path_36"></path>
                </g>
            </svg>
        )
    }

    const renderInstagramSVG = fillColour => {
        return (
            <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Instagram" stroke="none" strokeWidth="1" fillRule="nonzero">
                    <path fill={`${fillColour ? fillColour : colours.black}`} d="M12.082,18.29 C8.65341627,18.29 5.874,15.5105837 5.874,12.082 C5.874,8.65341627 8.65341627,5.874 12.082,5.874 C15.5105837,5.874 18.29,8.65341627 18.29,12.082 C18.2861423,15.5089846 15.5089846,18.2861423 12.082,18.29 M12.082,7.941 C9.79498885,7.941 7.941,9.79498885 7.941,12.082 C7.941,14.3690111 9.79498885,16.223 12.082,16.223 C14.3690111,16.223 16.223,14.3690111 16.223,12.082 C16.2202449,9.79613101 14.367869,7.94375506 12.082,7.941" id="Path_26"></path>
                    <path fill={`${fillColour ? fillColour : colours.black}`} d="M17.857,24.165 L6.308,24.165 C2.82617753,24.1605913 0.00485671424,21.3388231 0.001,17.857 L0.001,6.308 C0.00485671424,2.82617688 2.82617753,0.00440872737 6.308,-7.0110584e-16 L17.857,-7.0110584e-16 C21.339213,0.00385780065 24.1611422,2.825787 24.165,6.308 L24.165,17.857 C24.1611422,21.339213 21.339213,24.1611422 17.857,24.165 M6.308,2.067 C3.96690257,2.06975521 2.06975521,3.96690257 2.067,6.308 L2.067,17.857 C2.06865303,20.1988784 3.9661226,22.0972428 6.308,22.1 L17.857,22.1 C20.200049,22.0988969 22.0993448,20.2000487 22.101,17.857 L22.101,6.308 C22.0982448,3.96690257 20.2010974,2.06975521 17.86,2.067 L6.308,2.067 Z" id="Path_27"></path>
                    <path fill={`${fillColour ? fillColour : colours.black}`} d="M19.892,5.595 C19.892,6.38311034 19.2531103,7.022 18.465,7.022 C17.6768897,7.022 17.038,6.38311034 17.038,5.595 C17.038,4.80688966 17.6768897,4.168 18.465,4.168 C19.2531103,4.168 19.892,4.80688966 19.892,5.595" id="Path_28"></path>
                </g>
            </svg>
        )
    }

    const renderLinkedinSVG = fillColour => {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="LinkedIn" stroke="none" strokeWidth="1" fillRule="nonzero">
                    <motion.rect fill={`${fillColour ? fillColour : colours.black}`} x="0.392" y="8.099" width="4.746" height="15.662"></motion.rect>
                    <path fill={`${fillColour ? fillColour : colours.black}`} d="M8.276,12.958 C8.276,11.308 8.276,9.312 8.239,7.867 L12.839,7.867 C12.9458458,8.62731619 13.0069557,9.3933605 13.022,10.161 C13.711,8.818 15.195,7.404 18.678,7.404 C22.478,7.404 23.712,10.147 23.712,15.077 L23.712,23.76 L18.966,23.76 L18.966,15.62 C18.966,13.231 18.009,11.544 16.466,11.544 C13.792,11.544 13.021,13.251 13.021,16.796 L13.021,23.76 L8.276,23.76 L8.276,12.958 Z" id="Path_29"></path>
                    <path fill={`${fillColour ? fillColour : colours.black}`} d="M5.53,2.765 C5.53,4.29206733 4.29206733,5.53 2.765,5.53 C1.23793267,5.53 0,4.29206733 0,2.765 C0,1.23793267 1.23793267,0 2.765,0 C4.29206733,0 5.53,1.23793267 5.53,2.765" id="Path_30"></path>
                </g>
            </svg>
        )
    }

    const renderFollow = () => {
        if (!data.contact_follow) return
        
        return (
            <Social>
                <span>follow us&nbsp;</span>
                {data.contact_follow.instagram && (
                    <ExternalLink
                        href={data.contact_follow.instagram}
                        target={'_blank'}
                    >
                        {renderInstagramSVG(theme && theme)}
                    </ExternalLink>
                )}
                {data.contact_follow.facebook && (
                    <ExternalLink
                        href={data.contact_follow.facebook}
                        target={'_blank'}
                    >
                        {renderFacebookSVG(theme && theme)}
                    </ExternalLink>
                )}
                {data.contact_follow.linkedin && (
                    <ExternalLink
                        href={data.contact_follow.linkedin}
                        target={'_blank'}
                    >
                        {renderLinkedinSVG(theme && theme)}
                    </ExternalLink>
                )}
            </Social>
        )
    }

    const renderCopyright = () => {
        return (
            <Copyright>
                (c) pepr agency. all rights reserved.
            </Copyright>
        )
    }

	return (
		<Wrapper
            style={{
                backgroundColor: bgColour && bgColour
            }}
        >
            <Container>
                <Grid
                    theme={theme}
                >
                    <Left>
                        <Col>
                            {renderEmail()}
                        </Col>
                        <Col>
                            {renderPhone()}
                        </Col>
                    </Left>
                    <Right>
                        <Col>
                            {renderFollow()}
                        </Col>
                        <Col>
                            {renderCopyright()}
                        </Col>
                    </Right>
                </Grid>
            </Container>
        </Wrapper>
	)
}

// Shared

const Description = styled.div``
const ExternalLink = styled.a``


// Layout

const Wrapper = styled.div`
    background-color: inherit;
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Col = styled.div``
const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex w-full`};
    ${tw`border-t`};
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    ${media.tabPor`
        ${tw`block`};
        padding-top: 0.5rem;
    `}

    ${props => {
        if (props.theme)
            return css`
                border-color: ${props.theme};
                color: ${props.theme};
            `
    }}

    ${Left}, ${Right} {
        ${tw`flex items-center w-1/2`};

        ${media.tabPor`
            ${tw`block w-full`};
        `}
    }

    ${Left} {
        ${tw`justify-start`};
    }

    ${Right} {
        ${tw`justify-end`};

        ${media.tabPor`
            > *:first-child {
                margin-top: 1.75rem;
            }
        `}
    }

    ${Col} {
        &:first-child {
            margin-right: 4rem;
        }

        ${media.tabPor`
            margin-top: 2rem;
        `}
    }
`


const textStyles = css`
    ${tw`font-body`};
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.67;
    letter-spacing: 0.04em;

    ${media.mob`
        font-size: 11px;
    `}
`

const Email = styled.div`
    &, * {
        ${textStyles}
    }

    ${ExternalLink} {
        text-decoration: underline;
    }
`

const Phone = styled.div`
    &, * {
        ${textStyles}
    }

    ${ExternalLink} {
        text-decoration: underline;
    }
`

// Social

const Social = styled.div`
    ${tw`flex items-center`};

    &, * {
        ${textStyles}
    }

    ${ExternalLink} {
        margin-left: 1.5rem;
    }
`

const Copyright = styled.div`
    ${tw`font-body`};
    font-weight: 300;
    font-size: 0.625rem;
    line-height: 1;
    letter-spacing: 0.04rem;
`


export default Footer

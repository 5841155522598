import { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from './utils'

/*-----------------------------------*/
/* Global Type
/*-----------------------------------*/

const type = {
    body: css`
        ${tw`font-body`};
        font-weight: 300;
        font-size: 0.875rem;
        line-height: 1.5;
        letter-spacing: 0.05em;

        ${media.mob`
            font-size: 1rem;
        `}
    `,
    heading: css`
        ${tw`font-body text-black uppercase`};
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.19;
        letter-spacing: 0.05em;
    `
}

export default type

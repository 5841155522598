import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { Squash as Hamburger } from 'hamburger-react'
import Headroom from 'react-headroom'
import { useMount, useWindowScroll, useLocation } from 'react-use'

import { media } from '../../styles/utils'
import { container, padding, hoverState, type, colours } from '../../styles/global'

import { MenuContext } from '../Menu/context'
import { IsMobileContext } from '../../context/IsMobileContext'
import { ThemeContext } from '../../context/ThemeContext'
import { IsHomeContext } from '../../context/IsHomeContext'

export const headerHeight = 100
export const mobHeaderHeight = 64

const Header = props => {
    const menu = useContext(MenuContext)
    const { isMobile } = useContext(IsMobileContext)
    const isHome = useContext(IsHomeContext)
    const { Theme } = useContext(ThemeContext)
    const { y: scrollY } = useWindowScroll()
    const location = useLocation()
    const [loaded, setLoaded] = useState(false)
    // console.log(isHome.isHome)

    useMount(() => {
        isHome.setIsHome(location.pathname === '/')
        setLoaded(true)
    })

    useEffect(() => {
        if (menu.active) {
            menu.toggleActive()
        }

        setLoaded(false)
        isHome.setIsHome(location.pathname === '/')
        setLoaded(true)
    }, [location.pathname])

    const renderLogoSVG = fillColour => {
        return (
            <svg width="220px" height="78px" viewBox="0 0 220 78" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="pepr-logo" stroke="none" strokeWidth="1" fill={`${fillColour}`} fillRule="evenodd">
                    <path d="M41.7940296,28.1201974 C41.7940296,12.7757401 36.5442434,5.81027961 30.0833717,5.81027961 C27.0551151,5.81027961 23.0169572,8.63699013 22.1085526,13.8862336 L22.1085526,42.1524342 C23.0169572,46.8972697 26.7522533,49.8243914 30.3865954,49.8243914 C35.9387007,49.8243914 41.7940296,43.8684704 41.7940296,28.1201974 Z M7.16754934,77.0819572 L7.16754934,14.8955921 L0,9.94902961 L0,9.54521382 L22.1085526,0.0560855263 L22.1085526,10.9585691 C24.4301316,5.20419408 30.0833717,0.762763158 37.0491941,0.762763158 C47.6488158,0.762763158 58.9556579,10.1512993 58.9556579,27.5146546 C58.9556579,44.2728289 47.7499507,54.3680428 36.3425164,54.3680428 C29.8816447,54.3680428 24.2285855,50.6322039 22.1085526,45.0806414 L22.1085526,77.0819572 L7.16754934,77.0819572 Z" id="Fill-1"></path>
                    <path d="M78.7402796,16.0808059 L90.9555263,16.0808059 C97.3152632,16.0808059 99.2333882,14.6938651 99.2333882,10.5547533 C99.2333882,6.92077303 96.6085855,2.22189145 90.5517105,2.22189145 C84.3935197,2.22189145 79.6490461,6.28881579 78.7402796,16.0808059 Z M65.5158553,27.8173355 C65.5158553,12.1700164 78.1347368,0.762763158 91.460477,0.762763158 C100.849013,0.762763158 111.448635,4.49769737 114.880707,17.4194408 L78.6393257,17.4194408 C78.5387336,18.2270724 78.5387336,19.0348849 78.5387336,19.9432895 C78.5387336,33.8749342 85.9078289,43.7678783 97.9213487,43.7678783 C104.786036,43.7678783 113.669622,41.0419408 116.395016,28.524375 L117.202829,28.524375 C115.284704,47.7049013 104.786036,54.3680428 91.8642928,54.3680428 C77.2259704,54.3680428 65.5158553,44.4740132 65.5158553,27.8173355 L65.5158553,27.8173355 Z" id="Fill-3"></path>
                    <path d="M158.489194,28.1201974 C158.489194,12.7757401 153.239589,5.81027961 146.778717,5.81027961 C143.750461,5.81027961 139.712122,8.63699013 138.803898,13.8862336 L138.803898,42.1524342 C139.712122,46.8972697 143.447599,49.8243914 147.081941,49.8243914 C152.633865,49.8243914 158.489194,43.8684704 158.489194,28.1201974 Z M123.862895,77.0819572 L123.862895,14.8955921 L116.695345,9.94902961 L116.695345,9.54521382 L138.803898,0.0560855263 L138.803898,10.9585691 C141.125477,5.20419408 146.778717,0.762763158 153.744539,0.762763158 C164.344161,0.762763158 175.651003,10.1512993 175.651003,27.5146546 C175.651003,44.2728289 164.445115,54.3680428 153.037862,54.3680428 C146.57699,54.3680428 140.923931,50.6322039 138.803898,45.0806414 L138.803898,77.0819572 L123.862895,77.0819572 Z" id="Fill-4"></path>
                    <path d="M184.340461,54.5113322 L184.340461,14.8955921 L177.172368,9.94902961 L177.172368,9.54521382 L199.280921,0.0560855263 L199.280921,15.2994079 C202.611678,5.91105263 207.357237,0.863355263 212.404934,0.863355263 C216.947862,0.863355263 219.97648,4.09388158 219.97648,8.93967105 C219.97648,14.0883224 217.148684,17.4194408 214.120066,18.0255263 L202.410855,11.8673355 C201.906086,12.3719243 199.280921,15.2994079 199.280921,17.6215296 L199.280921,54.5113322" id="Fill-5"></path>
                    <path d="M157.582418,72.8675822 L155.715132,68.6266118 L153.848026,72.8675822 L157.582418,72.8675822 Z M155.165132,66.8029276 L156.279786,66.8029276 L160.838997,77.0796053 L159.435049,77.0796053 L158.117944,74.0833717 L153.326974,74.0833717 L152.009868,77.0796053 L150.620395,77.0796053 L155.165132,66.8029276 L155.165132,66.8029276 Z" id="Fill-6"></path>
                    <path d="M168.061184,66.6292434 C170.492763,66.6292434 172.345395,68.1345066 172.924342,69.8425822 L171.433372,69.8425822 C170.883372,68.7281086 169.609688,67.9029276 168.061184,67.9029276 C165.774161,67.9029276 164.08074,69.668898 164.08074,71.9410855 C164.08074,74.2136349 165.774161,75.9796053 168.061184,75.9796053 C170.174161,75.9796053 171.650658,74.4888158 171.838816,72.4623191 L168.003109,72.4623191 L168.003109,71.2029276 L173.213816,71.2029276 C173.503289,74.9517928 171.057056,77.2532895 168.061184,77.2532895 C165.050477,77.2532895 162.734507,74.9373191 162.734507,71.9410855 C162.734507,68.9452138 165.050477,66.6292434 168.061184,66.6292434" id="Fill-7"></path>
                    <polyline id="Fill-9" points="176.687319 66.8029276 182.679605 66.8029276 182.679605 68.0766118 178.033372 68.0766118 178.033372 71.1886349 181.811184 71.1886349 181.811184 72.4623191 178.033372 72.4623191 178.033372 75.8059211 182.679605 75.8059211 182.679605 77.0796053 176.687319 77.0796053 176.687319 66.8029276"></polyline>
                    <polyline id="Fill-10" points="193.795395 77.0796053 187.542763 69.075477 187.542763 77.0796053 186.196711 77.0796053 186.196711 66.8029276 187.369079 66.8029276 193.621711 74.8070559 193.621711 66.8029276 194.953289 66.8029276 194.953289 77.0796053 193.795395 77.0796053"></polyline>
                    <path d="M203.767763,66.6292434 C206.358553,66.6292434 208.240132,68.3226645 208.688816,70.1899507 L207.270395,70.1899507 C206.821711,68.9017928 205.475658,67.9029276 203.767763,67.9029276 C201.480921,67.9029276 199.7875,69.668898 199.7875,71.9410855 C199.7875,74.2136349 201.480921,75.9796053 203.767763,75.9796053 C205.475658,75.9796053 206.821711,74.9807401 207.270395,73.6925822 L208.688816,73.6925822 C208.240132,75.5596875 206.358553,77.2532895 203.767763,77.2532895 C200.757237,77.2532895 198.441447,74.9373191 198.441447,71.9410855 C198.441447,68.9452138 200.757237,66.6292434 203.767763,66.6292434" id="Fill-12"></path>
                    <polyline id="Fill-14" points="214.550658 72.7228454 210.555921 66.8029276 212.1625 66.8029276 215.216447 71.3767928 218.284868 66.8029276 219.891447 66.8029276 215.882237 72.7373191 215.882237 77.0796053 214.550658 77.0796053 214.550658 72.7228454"></polyline>
                </g>
            </svg>
        )
    }

    return (
        <Wrapper
            active={menu.active}
            style={{
                opacity: isHome.isHome !== 'undefined' ? 1 : 0
            }}
        >
            <Headroom
                style={{
                    transform: menu.active && 'none'
                }}
                disable={ menu.active ? true : false}
            >
                <Container>
                    <Logo
                        as={Link}
                        to={'/'}
                        style={{
                            opacity: !loaded || isHome.isHome ? 0 : 1
                        }}
                    >
                        {renderLogoSVG(Theme?.headerColour)}
                    </Logo>
                    <NavToggle
                        href="javascript:void(0)"
                    >
                        <Hamburger
                            size={!isMobile ? 37 : 24}
                            color={!menu.active ? Theme?.headerColour : colours.black}
                            duration={'0.4'}
                            toggled={menu.active}
                            toggle={menu.toggleActive}
                        />
                        {!isMobile && (
                            <Label
                                onClick={() => menu.toggleActive()}
                                style={{
                                    color: !menu.active ? Theme?.headerColour : colours.black
                                }}
                            >
                                Menu
                            </Label>
                        )}
                    </NavToggle>
                </Container>
            </Headroom>
        </Wrapper>
    )
}

// Shared

const Icon = styled.img``
const Label = styled.div``

// Layout

const Wrapper = styled.div`
    ${tw`absolute top-0 inset-x-0`};
    z-index: 20;
    transition: opacity 0.4s ease;

    ${props => {
        if (props.active)
            return css`
                ${tw`fixed`};
            `
    }}
`

const Container = styled.div`
    ${container}
    ${padding}
    ${tw`flex justify-between items-end`};
    height: ${headerHeight}px;

    ${media.mob`
        height: ${mobHeaderHeight}px;
    `}
`

// Logo

const Logo = styled.div`
    transition: opacity 0.4s ease;
    
    svg {
        height: 54px;
        width: auto;

        ${media.mob`
            height: 38px;
        `}

        #pepr-logo {
            transition: fill 0.4s ease;
        }
    }
`

// Nav Toggle

const NavToggle = styled.a`
    ${tw`relative block text-center`};

    ${Label} {
        margin-top: -4px;
        ${tw`font-body uppercase`};
        font-size: 12px;
        line-height: 1.17;
        letter-spacing: 0.05em;
        transition: color 0.4s ease;
        user-select: none;
    }
`

export default Header

import React, { useContext } from "react"
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { use100vh } from 'react-div-100vh'

import ThemeProvider from './context/ThemeContext'

const BGTransition = ({children}) =>  {
    const screenHeight = use100vh()	
    const { Theme } = useContext(ThemeProvider) 
    // console.log(Theme)

	return (
        <Wrapper
            key={'backgroundColour'}
            initial={{
                backgroundColor: Theme.bgColour ? Theme.bgColour : 'white'
            }}
            animate={{
                backgroundColor: Theme.bgColour && Theme.bgColour
            }}
            transition={{
                duration: 0.4
            }}
            style={{
                minHeight: screenHeight
            }}
        >
            {children}	
        </Wrapper>
	)
}

const Wrapper = styled(motion.div)`
	width: 100%;
	overflow: hidden;
`

export default BGTransition
import React, { useState } from 'react'

export const IsHomeContext = React.createContext()

const IsHomeProvider = ({ children }) => {
    const [isHome, setIsHome] = useState(undefined)
    console.log(isHome)

    return (
        <IsHomeContext.Provider value={{ isHome: isHome, setIsHome: setIsHome }}>
            {children}
        </IsHomeContext.Provider>
    )
}


export default IsHomeContext

export { IsHomeProvider }